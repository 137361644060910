import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEvent, useKey, usePrevious } from 'react-use';
import { getRandomId } from '../../utils/id';
import { maxMediaQuery } from '../grid';
import Icon from '../icon/Icon';
import { DropdownItem } from './props';

type Props = {
  items: DropdownItem[];
  value?: DropdownItem['value'];
  label?: React.ReactNode;
  placeholder?: string;
  withClear?: boolean;
  onItemSelect(value: string | undefined): void;
};

const listAnimationVariants: AnimationProps['variants'] = {
  closed: {
    height: 0,
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  opened: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
  },
};

const listAnimationTransition: AnimationProps['transition'] = {
  height: { type: 'spring', stiffness: 250, damping: 30, duration: 0.3 },
  opacity: { duration: 0.3 },
};

const clearToggleAnimationVariants: AnimationProps['variants'] = {
  closed: {
    rotate: 0,
    y: -3,
    x: '-50%',
  },
  opened: {
    rotate: -180,
    y: -9,
    x: '-50%',
  },
  clear: {
    y: -6,
    rotate: 0,
    x: '-50%',
  },
};

const clearToggleAnimationTransition: AnimationProps['transition'] = {
  duration: 0.2,
};

const Dropdown: FC<Props> = ({ value, items, label, placeholder = 'Select', withClear = false, onItemSelect }) => {
  const elementIdRef = useRef(getRandomId('dropdown'));
  const boxRef = useRef<HTMLDivElement | null>(null);
  const toggleRef = useRef<HTMLButtonElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const itemsRef = useRef<HTMLLIElement[]>([]);
  const [listOpen, setListOpen] = useState(false);
  const [temporaryValue, setTemporaryValue] = useState<string | undefined>(undefined);
  const previousListOpen = usePrevious(listOpen);
  const selectedItem = useMemo(() => {
    return items.find((item) => item.value === value);
  }, [items, value]);
  const { current: elementId } = elementIdRef;
  const clearToggleVariant = value != null ? 'clear' : listOpen === true ? 'opened' : 'closed';

  const toggleList = useCallback(() => {
    setListOpen((currentValue) => !currentValue);
  }, [setListOpen]);

  const closeList = useCallback(() => {
    setListOpen(false);
    setTemporaryValue(undefined);
  }, [setListOpen]);

  const handleMouseClick = useCallback(
    (event: MouseEvent) => {
      if (event.target instanceof Element && boxRef.current?.contains(event.target as Node) === false) {
        closeList();
      }
    },
    [closeList]
  );

  const itemSelect = useCallback(
    (itemValue: string | undefined, shouldCloseList = true) => {
      onItemSelect(itemValue);

      if (shouldCloseList === true) {
        closeList();
      }
    },
    [onItemSelect, closeList]
  );

  const handleClearToggle = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (value != null) {
        itemSelect(undefined);
      } else {
        toggleList();
      }
    },
    [value, itemSelect, toggleList]
  );

  const handleElementInView = useCallback(() => {
    const currentItemIndex = items.findIndex((item) =>
      temporaryValue != null ? item.value === temporaryValue : item.value === value
    );
    const itemElement = itemsRef.current[currentItemIndex];
    const { current: listElement } = listRef;
    const { current: toggleElement } = toggleRef;

    if (itemElement == null || listElement == null || toggleElement == null) {
      return;
    }

    const listRects = listElement.getBoundingClientRect();
    const itemRects = itemElement.getBoundingClientRect();
    const toggleRects = toggleElement.getBoundingClientRect();
    const itemOffsetTop = itemElement.offsetTop - toggleRects.height;
    const itemOffsetBottom = itemOffsetTop + itemRects.height;
    const listScrollBottom = listElement.scrollTop + listRects.height;

    if (itemOffsetBottom > listScrollBottom) {
      listElement.scrollTop = itemOffsetBottom;
    } else if (itemOffsetTop < listElement.scrollTop) {
      listElement.scrollTop = itemOffsetTop;
    }
  }, [value, temporaryValue]);

  const handleArrowUpKey = useCallback(
    (currentItemIndex: number) => {
      const newTemporaryValue = items[currentItemIndex <= 0 ? items.length - 1 : currentItemIndex - 1]?.value;

      if (newTemporaryValue != null) {
        setTemporaryValue(newTemporaryValue);
      }
    },
    [items, setTemporaryValue]
  );

  const handleArrowDownKey = useCallback(
    (currentItemIndex: number) => {
      const newTemporaryValue =
        items[currentItemIndex < 0 || currentItemIndex === items.length - 1 ? 0 : currentItemIndex + 1]?.value;

      if (newTemporaryValue != null) {
        setTemporaryValue(newTemporaryValue);
      }
    },
    [items, setTemporaryValue]
  );

  const handleHomeKey = useCallback(() => {
    const newTemporaryValue = items[0]?.value;

    if (newTemporaryValue != null) {
      setTemporaryValue(newTemporaryValue);
    }
  }, [items, setTemporaryValue]);

  const handleEndKey = useCallback(() => {
    const newTemporaryValue = items[items.length - 1]?.value;

    if (newTemporaryValue != null) {
      setTemporaryValue(newTemporaryValue);
    }
  }, [items, setTemporaryValue]);

  const handleListKey = useCallback(
    (event: React.KeyboardEvent<HTMLUListElement>) => {
      if (['ArrowUp', 'ArrowDown', 'Enter', ' ', 'Home', 'End'].includes(event.key) === false) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const currentItemIndex = items.findIndex((item) =>
        temporaryValue != null ? item.value === temporaryValue : item.value === value
      );

      switch (event.key) {
        case 'ArrowUp':
          handleArrowUpKey(currentItemIndex);
          break;

        case 'ArrowDown':
          handleArrowDownKey(currentItemIndex);
          break;

        case 'Home':
          handleHomeKey();
          break;

        case 'End':
          handleEndKey();
          break;

        case 'Enter':
        case ' ':
          if (temporaryValue != null) {
            itemSelect(temporaryValue);
          }
          break;

        default:
          break;
      }
    },
    [value, temporaryValue, items, onItemSelect, handleArrowUpKey, handleArrowDownKey, handleHomeKey, handleEndKey]
  );

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items]);

  useEffect(() => {
    if (listOpen === true && previousListOpen === false) {
      setTimeout(() => {
        listRef.current?.focus();
      }, 100);
    } else if (listOpen === false && previousListOpen === true) {
      setTimeout(() => {
        toggleRef.current?.focus();
      }, 100);
      setTimeout(() => {
        if (listRef.current != null) {
          listRef.current.scrollTop = 0;
        }
      }, 300);
    }
  }, [listOpen, previousListOpen]);

  useEffect(() => {
    if (listOpen === false) {
      return;
    }

    handleElementInView();
  }, [listOpen, handleElementInView]);

  useKey('Escape', closeList, { event: 'keyup' });
  useEvent('click', handleMouseClick);

  return (
    <Container id={`${elementId}`}>
      {label != null && <Label id={`${elementId}-label`}>{label}</Label>}
      <ToggleContainer>
        <BoxContainer withClear={withClear}>
          <Box ref={boxRef}>
            <Toggle
              ref={toggleRef}
              listOpen={listOpen}
              id={`${elementId}-button`}
              className="btn-restart"
              aria-haspopup="listbox"
              aria-labelledby={`${label != null ? `${elementId}-label` : ''} ${elementId}-button`}
              aria-expanded={listOpen === true ? 'true' : undefined}
              onClick={toggleList}
            >
              <span>{selectedItem?.label ?? placeholder}</span>
              {withClear === false && <Icon iconType="chevron" />}
            </Toggle>
            <List
              ref={listRef}
              tabIndex={-1}
              role="listbox"
              aria-labelledby={label != null ? `${elementId}-label` : undefined}
              aria-activedescendant={selectedItem != null ? `${elementId}=${selectedItem.key}` : undefined}
              animate={listOpen === true ? 'opened' : 'closed'}
              variants={listAnimationVariants}
              initial="closed"
              transition={listAnimationTransition}
              onKeyDown={handleListKey}
            >
              {items.map((item, index) => (
                <Item
                  ref={(element) => {
                    itemsRef.current[index] = element as HTMLLIElement;
                  }}
                  key={item.key}
                  id={`${elementId}-${item.key}`}
                  className={
                    item.value === value || (temporaryValue != null && temporaryValue === item.value)
                      ? 'selected'
                      : undefined
                  }
                  value={item.value}
                  role="option"
                  aria-selected={
                    item.value === value || (temporaryValue != null && temporaryValue === item.value)
                      ? 'true'
                      : undefined
                  }
                  onClick={() => itemSelect(item.value)}
                >
                  <span>{item.label}</span>
                </Item>
              ))}
            </List>
          </Box>
        </BoxContainer>
        {withClear === true && (
          <ClearToggle
            listOpen={listOpen}
            className="btn-restart"
            title={value == null ? 'Toggle list' : 'Clear filter'}
            aria-haspopup="listbox"
            aria-labelledby={`${label != null ? `${elementId}-label` : ''}`}
            aria-expanded={listOpen === true ? 'true' : undefined}
            onClick={handleClearToggle}
          >
            <ClearToggleIcon
              hasClear={value != null}
              animate={clearToggleVariant}
              variants={clearToggleAnimationVariants}
              initial="closed"
              transition={clearToggleAnimationTransition}
            >
              <Icon iconType="chevron" />
              <Icon iconType="chevron" />
            </ClearToggleIcon>
          </ClearToggle>
        )}
      </ToggleContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${maxMediaQuery.sm} {
    flex-direction: column;
    justify-content: center;
  }
`;

const Label = styled.div`
  margin-right: ${({ theme }) => theme.spacing.unit * 1.5}px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  color: ${({ theme }) => theme.colors.primary.copy};
  font-weight: 700;

  ${maxMediaQuery.sm} {
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 1.5}px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const BoxContainer = styled.div<{ withClear: boolean }>`
  position: relative;
  width: ${({ withClear }) => (withClear === true ? '165px' : '180px')};
  height: 38px;
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Toggle = styled.button<{ listOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 42px 10px 20px;
  font-size: ${({ theme }) => theme.typography.smallBody.fontSize};
  line-height: ${({ theme }) => theme.typography.smallBody.lineHeight};
  color: ${({ theme }) => theme.colors.primary.copy};

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: ${({ listOpen }) =>
      listOpen === true ? `translateY(-50%) rotate(-180deg)` : `translateY(-50%) rotate(0)`};
    transition: transform 0.2s ease;
  }
`;

const List = styled(motion.ul)`
  list-style: none;
  max-height: 228px;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

const Item = styled.li`
  display: block;
  width: 100%;
  padding: 8px 20px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  color: ${({ theme }) => theme.colors.primary.copy};
  background-color: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s ease;

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover,
  &.selected {
    background-color: ${({ theme }) => theme.colors.secondary.grey.light};
  }
`;

const ClearToggle = styled.button<{ listOpen: boolean }>`
  position: relative;
  height: 38px;
  width: 36px;
  margin-left: ${({ theme }) => theme.spacing.unit * 1.5}px;
  color: ${({ theme }) => theme.colors.primary.copy};
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  }
`;

const ClearToggleIcon = styled(motion.span)<{ hasClear: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;

  svg {
    display: block;
    width: 12px;
    height: 6px;

    &:last-of-type {
      margin-top: -1px;
      opacity: ${({ hasClear }) => (hasClear === true ? '1' : '0')};
      transform: rotate(-180deg);
      transition: opacity 0.2s ease;
    }
  }
`;

export default Dropdown;
